:root {
  --step--5: clamp(0.4rem, calc(0.21rem + 0.97vw), 0.98rem);
  --step--4: clamp(0.48rem, calc(0.23rem + 1.24vw), 1.23rem);
  --step--3: clamp(0.58rem, calc(0.26rem + 1.6vw), 1.54rem);
  --step--2: clamp(0.69rem, calc(0.29rem + 2.04vw), 1.92rem);
  --step--1: clamp(0.83rem, calc(0.31rem + 2.61vw), 2.4rem);
  --step-0: clamp(1rem, calc(0.33rem + 3.33vw), 3rem);
  --step-1: clamp(1.2rem, calc(0.35rem + 4.25vw), 3.75rem);
  --step-2: clamp(1.44rem, calc(0.36rem + 5.41vw), 4.69rem);
  --step-3: clamp(1.73rem, calc(0.35rem + 6.89vw), 5.86rem);
  --step-4: clamp(2.07rem, calc(0.32rem + 8.75vw), 7.32rem);
  --step-5: clamp(2.49rem, calc(0.27rem + 11.11vw), 9.15rem);
  --step-6: clamp(2.99rem, calc(0.17rem + 14.1vw), 11.44rem);
  --step-7: clamp(3.58rem, calc(0.01rem + 17.87vw), 14.31rem);
  --step-8: clamp(4.3rem, calc(-0.23rem + 22.64vw), 17.88rem);
  --step-9: clamp(5.16rem, calc(-0.57rem + 28.65vw), 22.35rem);

  --app-height: 100vh;
  --project-width: 18rem;
  --project-gap: calc(var(--project-width) * 0.25);
  --projects-header: clamp(2rem, calc(0.33rem + 3.33vw), 3rem);

  --project-page-section-gap: 6rem;
}

@font-face {
  font-family: Briar;
  src: url("../assets/fonts/Briar/Briar Display.woff") format("woff");
}

html,
body {
  overscroll-behavior: none;
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
  color: #3b3b3b;
  margin: 0;
  min-height: 100%;
  background: #eeeeee;
}

ul,
p {
  list-style: none;
  padding: 0;
  margin: 0;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 0;
  height: min-content;
  font-size: 1rem;
  text-transform: uppercase;
}

nav .name {
  font-family: Briar;
  font-size: 1rem;
  line-height: 1;
}

nav .links {
  display: none;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 1rem;
  height: min-content;
  font-size: 1rem;
  text-transform: uppercase;
}

.links {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.text-block {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.text-block p,
.text-block li {
  font-size: var(--step-0);
  font-weight: 300;
  margin: 0;
}

.text-block .eyebrow {
  font-style: italic;
}

.text-block h3 {
  font-size: var(--step-1);
  margin: 0 0 1rem 0;
}

.text-block ul {
  list-style-type: disc;
  padding: 0;
  margin-left: 2rem;
}

.image-block {
  display: flex;
  flex: 1;
  position: relative;
}

.image-block img {
  width: 100%;
  height: auto;
}

.project-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: var(--project-page-section-gap);
}

.project-title h1 {
  /* font-family: Briar; */
  font-size: var(--step-3);
  font-weight: 600;
  margin: 1rem 0;
}

.project-title h2 {
  font-size: var(--step-0);
  font-weight: 400;
  margin: 0;
}

.project-title p {
  font-size: var(--step--1);
  font-weight: 400;
  margin: 0;
}

.project-page-wrapper {
  max-width: 1000px;
  margin: auto;
  padding: 0 3rem;
}

/*** media queries ***/
@media screen and (min-width: 540px) {
  nav .links {
    display: flex;
  }

  nav .name {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 768px) {
  :root {
    --project-page-section-gap: 10rem;
  }

  nav {
    padding: 2.5rem 2.5rem 0;
  }

  .links {
    gap: 1.75rem;
  }

  .text-block p,
  .text-block li {
    font-size: var(--step--4);
  }

  .text-block h3 {
    font-size: var(--step--2);
  }

  .image-block {
    width: 50%;
  }

  .image-block img {
    width: 75%;
  }

  .project-title h1 {
    font-size: var(--step-2);
  }

  .project-title h2 {
    font-size: var(--step--3);
  }

  .project-title p {
    font-size: var(--step--4);
  }
}

@media screen and (min-width: 920px) {
  :root {
    --project-width: clamp(14.8rem, calc(-0.23rem + 22.64vw), 18rem);
  }
}

@media screen and (min-width: 1280px) {
  nav {
    padding: 3rem 6rem 0;
  }
}
