/*** canvas/webgl elements ***/

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--app-height);
  width: 100vw;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.8s ease-in;
  pointer-events: none;
}

.planes-loaded #canvas {
  opacity: 1;
}

#background-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

/*** floating objects ***/

#planes {
  overflow: hidden;
  opacity: 0;
  position: relative;
  height: var(--app-height);
}

.planes-loaded #planes {
  opacity: 1;
}

.plane-wrapper {
  position: absolute;
}

.plane {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: center center;
}

.text-plane {
  opacity: 0;
}

body.is-fullscreen .plane {
  cursor: auto;
}

.plane img {
  height: 100%;
  width: auto;
  display: none;
}

/*** content ***/

#home-header {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

#header-content {
  color: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1280px;
}

#header-content h1 {
  font-family: Briar;
  font-size: var(--step-7);
  line-height: 110%;
  margin: 0;
  padding: 1rem;
}

#header-content p {
  font-size: var(--step-1);
  line-height: 140%;
  padding: 0 1rem;
  text-transform: uppercase;
}

.home-main {
  background: inherit;
  margin-top: 100vh;
  z-index: 20;
}

.projects-wrapper {
  position: relative;
  background: inherit;
  z-index: 20;
  max-width: 32rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: var(--project-gap);
}

.projects-title {
  font-family: Briar;
  font-size: var(--projects-header);
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: var(--project-gap) 0;
}

.home-project {
  position: relative;
  width: var(--project-width);
}

.home-project:nth-child(even) {
  left: 30px;
}

.home-project:nth-child(odd) {
  right: 30px;
  align-self: flex-end;
}

.home-project img {
  width: var(--project-width);
  height: auto;
  filter: drop-shadow(0px 1px 8px rgba(25, 25, 39, 0.05));
  transition: all 0.3s ease-in-out;
}

.home-project .project-info {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  margin: 0;
}

.home-project .project-info h3 {
  margin: 0;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
}

.home-project .project-info p {
  margin: 0.3rem 0 0 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
}

.home-project:hover img {
  filter: drop-shadow(0px 1px 8px rgba(25, 25, 39, 0.2));
}

#vail:hover img,
#lootbox:hover img,
#sample-projects:hover img,
#mirage:hover img {
  filter: drop-shadow(0px 1px 8px rgba(25, 25, 39, 0.6));
}

.home-project:hover .project-info {
  opacity: 1;
}

.about_wrapper {
  padding: var(--project-gap) 1rem;
}

.about-title {
  font-family: Briar;
  font-size: var(--projects-header);
  text-align: center;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 40rem;
  margin: auto;
}

.about img {
  margin: 0 0 1rem 0;
  height: calc(var(--project-width) * 1.5);
  width: auto;
}

.about p {
  margin: 0.75rem 0 0;
}

/*** media queries ***/

@media screen and (min-width: 920px) {
  :root {
    --project-width: clamp(14.8rem, calc(-0.23rem + 22.64vw), 18rem);
  }

  .projects-wrapper {
    width: 100%;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 1fr repeat(12, calc(var(--project-width) * 0.25)) 1fr;
    grid-template-rows: repeat(4, 1fr);
    margin: 0 auto;
    gap: var(--project-gap) 0;
  }

  .projects-title {
    font-size: var(--step-2);
  }

  .home-project:nth-child(even) {
    left: 0;
  }

  .home-project:nth-child(odd) {
    right: 0;
    align-self: flex-end;
  }

  #vail {
    grid-column: 3 / span 4;
    grid-row: 1 / span 2;
  }

  #lootbox {
    grid-column: 9 / span 4;
    grid-row: 2 / span 2;
  }

  #sample-projects {
    grid-column: 4 / span 4;
    grid-row: 3 / span 2;
  }

  #f3 {
    grid-column: 10 / span 4;
    grid-row: 4 / span 2;
  }

  #mirage {
    grid-column: 2 / span 4;
    grid-row: 5 / span 2;
  }

  #spacespromo {
    grid-column: 8 / span 4;
    grid-row: 6 / span 2;
  }

  #brewgood {
    grid-column: 3 / span 4;
    grid-row: 7 / span 2;
  }

  #e-immigrate {
    grid-column: 9 / span 4;
    grid-row: 8 / span 2;
  }

  section .project-info {
    opacity: 0;
  }

  .about_wrapper {
    padding: var(--project-gap) 3rem;
  }

  .about {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1040px;
    gap: 2rem;
  }

  .about img {
    order: 2;
  }

  .about .content {
    order: 1;
  }
}

@media screen and (min-width: 1280px) {
  #header-content p {
    margin-bottom: 2rem;
  }
}

/*** handling errors ***/

.no-curtains .plane-title {
  z-index: 1;
}

.no-curtains .plane {
  display: flex;
  overflow: hidden;
}

.no-curtains .plane img {
  display: block;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}
